<template>
<div class="container is-max-desktop">
  <div v-if="!hasValidToken()" class="section">
    <article class="message is-danger">
      <div class="message-header">
        <p>Zanim przejdziesz dalej ...</p>
      </div>
      <div class="message-body">
        Losowania nie sa jeszcze dostepne dla wszystkich. Uzupelnij ankiete pod <a :href="cta">tym adresem</a>, a otrzymasz link.
      </div>
    </article>
  </div>

  <div v-else class="section">
    <h1 class="title">Zaczynamy!</h1>

    <Errors :errors="errors" />

    <form>
        <h2 class="subtitle pt-4">Jak nazwiesz to losowanie?</h2>

        <b-field>
            <b-input v-model="form.name"></b-input>
        </b-field>

        <ul>
          <li><b-button style="width: 100%; margin-top: 12px;" class="is-primary" @click="create">Stwórz losowanie</b-button></li>
          <li><b-button style="width: 100%; margin-top: 6px;" @click="signOut">Rezygnuje</b-button></li>
        </ul>
    </form>

  </div>
</div>
</template>

<script>
import Errors from '@/components/Organizer/Errors.vue';
import { auth } from '@/api/firebase.js';
import { organizeCTA } from '@/config.js';

export default {
  components: { Errors },
  data() {
    return {
      cta: organizeCTA,
      form: {
        name: '',
        participant: '',
        intro: '',
        participants: [],
        accept: false
      }
    }
  },
  computed: {
    errors() { return this.$store.state.organizer.errors; },
  },
  methods: {
    async create() {
      this.$store.dispatch('organizer/setToken', await auth.currentUser.getIdToken());
      this.$store.dispatch('organizer/create', { draw: { name: this.form.name }});
    },
    hasValidToken() {
      return (this.$route.query.key && this.$route.query.key.length > 5);
    },
    signOut() {
      auth.signOut().then(() => this.$router.push('sign-in'));
    }
  }
}
</script>

<style>

</style>
